<template>
  <section class="section">
    <div v-if="loaded" class="container is-max-desktop">
      <div class="tags are-medium">
        <span class="tag is-clickable is-info" @click="onClearTags()"
          >Clear</span
        >
        <span
          :class="tagClasses(tag)"
          v-for="tag in tags"
          :key="tag.id"
          @click="onClickTag(tag)"
        >
          {{ tag.name }}
        </span>
        <span class="tag is-clickable is-info is-light" @click="onEditTags()"
          >Edit</span
        >
      </div>

      <div class="columns is-multiline is-mobile">
        <div
          v-for="item in filteredItems"
          :key="item"
          class="column is-4-mobile is-one-fifth-tablet is-2-desktop"
          @click="onItemClick(item)"
        >
          <Item :item="item" />
        </div>
      </div>

      <!-- ADD BUTTON -->
      <div class="floating-btn" @click="add">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </div>

      <!-- END CONTAINER -->
    </div>
    <div v-else>Loading...</div>
  </section>
</template>

<script>
import { getItems } from "../lib/items";
import { getTags } from "../lib/tags";
import Item from "@/components/Item";

export default {
  props: ["entity"],
  name: "Home",
  components: {
    Item,
  },

  data() {
    return {
      tags: [],
      items: [],
      mapTags: new Map(),
      loaded: false,
    };
  },

  mounted() {
    this.load();
  },

  //------------------------
  // METHODS
  //------------------------

  methods: {
    async load() {
      this.tags = await getTags(this.entity);
      this.items = await getItems(this.entity);

      if (this.getSavedTags()) {
        this.mapTags = new Map(JSON.parse(this.getSavedTags()));
      } else {
        this.createMapTags();
      }
      console.log(this.mapTags);
      this.loaded = true;
    },

    add() {
      this.$router.push({
        name: "Form",
        params: {
          entity: this.entity,
          id: "new",
          searchTitle: "",
          searchImage: "",
          searchLink: "",
        },
      });
    },

    //------------------------
    // HELPERS
    //------------------------

    createMapTags() {
      this.mapTags = new Map(this.tags.map((tag) => [tag.id, 0]));
    },

    getTagsShow() {
      return [...this.mapTags].filter(([k, v]) => v == 1).map(([k, v]) => k);
    },

    getTagsNotShow() {
      return [...this.mapTags].filter(([k, v]) => v == -1).map(([k, v]) => k);
    },

    tagClasses(tag) {
      let color = "";
      if (this.mapTags.get(tag.id) == 1) {
        color = "is-primary";
      } else if (this.mapTags.get(tag.id) == -1) {
        color = "is-danger";
      }
      return ["tag", "is-clickable", color];
    },

    saveTags() {
      if (this.entity == "comics") {
        localStorage.comicsTags = JSON.stringify([...this.mapTags]);
      } else if (this.entity == "books") {
        localStorage.booksTags = JSON.stringify([...this.mapTags]);
      } else if (this.entity == "games") {
        localStorage.gamesTags = JSON.stringify([...this.mapTags]);
      } else if (this.entity == "tv") {
        localStorage.tvTags = JSON.stringify([...this.mapTags]);
      } else if (this.entity == "manga") {
        localStorage.mangaTags = JSON.stringify([...this.mapTags]);
      } else if (this.entity == "projects") {
        localStorage.projectsTags = JSON.stringify([...this.mapTags]);
      }
    },

    getSavedTags() {
      if (this.entity == "comics") {
        return localStorage.comicsTags;
      } else if (this.entity == "books") {
        return localStorage.booksTags;
      } else if (this.entity == "games") {
        return localStorage.gamesTags;
      } else if (this.entity == "tv") {
        return localStorage.tvTags;
      } else if (this.entity == "manga") {
        return localStorage.mangaTags;
      } else if (this.entity == "projects") {
        return localStorage.projectsTags;
      }
    },

    //------------------------
    // LISTENERS
    //------------------------

    onClickTag(tag) {
      if (this.mapTags.get(tag.id) == 0) {
        this.mapTags.set(tag.id, 1);
      } else if (this.mapTags.get(tag.id) == 1) {
        this.mapTags.set(tag.id, -1);
      } else {
        this.mapTags.set(tag.id, 0);
      }
      this.saveTags();
    },

    onClearTags() {
      this.createMapTags();
      this.saveTags();
    },

    onEditTags() {
      this.$router.push({
        name: "Tags",
        params: {
          entity: this.entity,
        },
      });
    },

    onItemClick(item) {
      this.$router.push({
        name: "Form",
        params: {
          id: item.id,
          entity: this.entity,
        },
      });
    },
  },

  //------------------------
  // COMPUTED
  //------------------------

  computed: {
    filteredItems() {
      return this.items.filter(
        (item) =>
          this.getTagsShow().every((tag) => item.tags.includes(tag)) &&
          item.tags.every((tag) => !this.getTagsNotShow().includes(tag))
      );
    },
  },
};
</script>

<style scoped>
.floating-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #5cb85c;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  box-shadow: 2px 2px 3px #244724;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-btn span {
  font-size: 22px;
}
</style>
