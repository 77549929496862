import { createStore } from 'vuex'
import { auth } from '@/firebase/config'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'

const store = createStore({
  //-----------------------------
  // STATE
  state: {
    user: null,
  },
  //-----------------------------
  // MUTATIONS
  mutations: {
    setUser(state, payload) {
      state.user = payload
      console.log('user state changed:', state.user)
    },
  },
  //-----------------------------
  // ACTIONS
  actions: {
    async signup(context, { email, password }) {
      const res = await createUserWithEmailAndPassword(auth, email, password)
      if (res) {
        context.commit('setUser', res.user)
      } else {
        throw new Error('could not complete signup')
      }
    },

    async login(context, { email, password }) {
      const res = await signInWithEmailAndPassword(auth, email, password)
      if (res) {
        context.commit('setUser', res.user)
      } else {
        throw new Error('could not complete login')
      }
    },

    async logout(context) {
      await signOut(auth)
      context.commit('setUser', null)
    },
  },
})

// Cargar user al arrancar la aplicación
const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('setUser', user)
  unsub()
})

export default store
