<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="tag-row is-flex is-align-items-center" v-for="tag in tags" :key="tag.id">
        <div v-if="editingTag && tag.id == editingTag.id" class="is-flex-grow-1">
          <input class="input" type="text" v-model="editingTag.name" @keydown.enter="onSave()" />
        </div>
        <div v-else class="name is-flex-grow-1" @click="onEdit(tag)">
          {{ tag.name }}
        </div>
        <div>
          <a class="button is-danger" style="margin-left: 5px;" @click="onDelete(tag)">Delete</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getTags, saveTag, deleteTag } from '../lib/tags'

export default {
  props: ['entity'],

  data() {
    return {
      tags: [],
      editingTag: null
    }
  },

  mounted() {
    this.loadTags()
  },

  methods: {
    async loadTags() {
      this.tags = await getTags(this.entity)
    },

    async onDelete(tag) {
      await deleteTag(this.entity, tag.id)
      this.loadTags()
    },

    onEdit(tag) {
      this.editingTag = tag
    },

    async onSave() {
      await saveTag(this.entity, this.editingTag.id, { name: this.editingTag.name })
      this.loadTags()
      this.editingTag = null
    }
  }
}
</script>

<style scoped>
.tag-row {
  padding: 10px;
  border-bottom: 1px solid #111;
}

.name {
  cursor: pointer;
}
</style>
