import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
require('@/assets/main.scss')
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '@/firebase/config'

let app

onAuthStateChanged(auth, (_) => {
  if (!app) {
    app = createApp(App).use(store).use(router).mount('#app')
  }
})
