<template>
  <section class="section">
    <div class="container is-max-desktop" id="search">
      <h1 class="title">Search</h1>

      <div class="columns is-multiline">
        <form @submit.prevent="onSearch">
          <div class="field column is-12 is-grouped">
            <div class="control is-expanded">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input v-model="title" id="image" class="input" type="text" placeholder="Title" />
                </div>
                <div class="control">
                  <button type="submit" class="button is-info">Search Title</button>
                </div>
              </div>
            </div>
            <a class="button is-info" @click="onCreate">Create</a>
          </div>
        </form>
      </div>

      <!-- RESULTS -->
      <div class="results">
        {{ message }}
        <div
          class="result is-flex"
          v-for="res in results"
          :key="res.title"
          @click="onResultClick(res)"
        >
          <img :src="res.img" style="height: 80px" />
          <div style="margin-left: 10px">
            <div style="font-weight: bold">{{ res.title }}</div>
            <div>{{ res.subtitle }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getSearchURL } from '../lib/items'
const axios = require('axios')
const slugify = require('slugify')

export default {
  props: ['entity'],

  data() {
    return {
      searchURL: '',
      title: '',
      results: [],
      searching: false,
      message: '',
    }
  },

  mounted() {
    this.searchURL = getSearchURL(this.entity)
  },

  methods: {
    onCreate() {
      this.$router.push({
        name: 'Form',
        params: {
          entity: this.entity,
          id: 'new',
          searchTitle: '',
          searchImage: '',
          searchLink: '',
        },
      })
    },

    onSearch() {
      this.searching = true
      this.message = 'Searching...'
      this.results = []

      axios
        .get(this.searchURL + this.title)
        .then((res) => {
          console.log(res.data)
          this.results = res.data
          this.message = ''
        })
        .catch((err) => {
          console.log(err)
          this.message = 'There was an error'
        })
        .finally(() => {
          this.searching = false
        })
    },

    onResultClick(res) {
      this.$router.push({
        name: 'Form',
        params: {
          entity: this.entity,
          id: slugify(res.title, { lower: true }),
          searchTitle: res.title,
          searchImage: res.img,
          searchLink: res.link,
        },
      })
    },
  },
}
</script>

<style scoped>
.result {
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.result:hover {
  background-color: #17222c;
}
</style>
