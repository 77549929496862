import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Form from '../views/Form.vue'
import Search from '../views/Search.vue'
import Login from '../views/Login.vue'
import Tags from '../views/Tags.vue'
import { auth } from '@/firebase/config'

const requireAuth = (to, from, next) => {
  if (auth.currentUser) {
    next()
  } else {
    console.log('redirigido a /login')
    next({ name: 'Login' })
  }
}

const requireNoAuth = (to, from, next) => {
  console.log('user', auth.currentUser)
  if (!auth.currentUser) {
    console.log('next')
    next()
  } else {
    console.log('redirigido a Home')
    next({ name: 'Home' })
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuth,
    props: { entity: 'books' },
  },
  {
    path: '/:entity',
    name: 'Items',
    component: Home,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/:entity/:id',
    name: 'Form',
    component: Form,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/search/:entity',
    name: 'Search',
    component: Search,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/tags/:entity',
    name: 'Tags',
    component: Tags,
    props: true,
    beforeEnter: requireAuth,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
