<template>
  <div>
    <div class="item" :title="item.title">
      <img v-if="item.image" class="item-img" :src="item.image" />
      <div v-else class="item-title">{{item.title}}</div>
      <!-- <img class="item-img" src="#" /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
}
</script>

<style>
.item {
  cursor: pointer;
  position: relative;
  padding-bottom: 130%;
}
.item-img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  box-shadow: 4px 4px 5px rgb(34, 34, 34);
}

.item-title {
  background-color: #1e8149;;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  box-shadow: 4px 4px 5px rgb(34, 34, 34);
  padding: 10px;
  font-size: 1.8rem;
}

.item-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: white;
}

.item-btns .icon {
  cursor: pointer;
}

.item-btns a:hover {
  color: #3273dc;
}

.item-btns a {
  color: white;
}

.status {
  margin-top: 4px;
  height: 4px;
}
</style>
