<template>
  <Navbar />
  <router-view :key="$route.fullPath" />
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  components: { Navbar },
  setup() {},
}
</script>
