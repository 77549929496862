import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyB4aAEig8_EhnZ1ohZIVfN_4XlYxGDmJ7U",
  authDomain: "logbook-firebase-3c358.firebaseapp.com",
  projectId: "logbook-firebase-3c358",
  storageBucket: "logbook-firebase-3c358.appspot.com",
  messagingSenderId: "810862662753",
  appId: "1:810862662753:web:f0c866b0f89851c56d8620"
};

// init firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()
const auth = getAuth()

export { db, auth }
