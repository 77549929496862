<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="columns is-mobile">
        <div class="column is-3">
          <img class="image" :src="image" />
          <Links
            :entity="entity"
            :link="link"
            :title="title"
            v-if="id != 'new'"
            :id="id"
          />
        </div>
        <div class="column">
          <h1 class="title">{{ title }}</h1>
          <div class="field">
            <input
              class="input"
              type="text"
              v-model="title"
              placeholder="Title"
            />
          </div>
          <div class="field is-grouped">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Image"
                  v-model="image"
                />
              </div>
              <div class="control">
                <button class="button is-link" @click="searchImage">Search</button>
              </div>
            </div>
          <div class="field" v-if="entity != 'games'">
            <input
              class="input"
              type="text"
              v-model="link"
              placeholder="Link"
            />
          </div>

          <!-- ADD TAG -->
          <form @submit.prevent="onAddTag">
            <div class="field is-grouped">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Add tag"
                  v-model="newTag"
                />
              </div>
              <div class="control">
                <button class="button is-link">Add</button>
              </div>
            </div>
          </form>

          <!-- TAGS -->
          <div class="tags are-medium">
            <span
              :class="tagClasses(tag)"
              v-for="tag in tags"
              :key="tag.id"
              @click="onClickTag(tag)"
            >
              {{ tag.name }}
            </span>
          </div>

          <!-- BUTTONS -->
          <div class="field is-grouped buttons">
            <div class="control">
              <a @click="onCancel" class="button is-link is-light">Cancel</a>
            </div>
            <div class="control" v-if="id != 'new'">
              <a @click="onDelete" class="button is-danger">Delete</a>
            </div>
            <div class="control">
              <button @click="onSubmit" class="button is-link">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Links from "@/components/Links";
import { getTags, saveTag } from "../lib/tags";
import { getItem, saveItem, deleteItem } from "../lib/items";
const slugify = require("slugify");

export default {
  components: {
    Links,
  },

  props: ["entity", "id", "searchTitle", "searchImage", "searchLink"],

  data() {
    return {
      title: "",
      image: "",
      link: "",
      newTag: "",
      selectedTags: new Set(),
      tags: [],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      this.tags = await getTags(this.entity);
      this.loadItem();
    },

    async loadItem() {
      const item = await getItem(this.entity, this.id);

      if (item.exists()) {
        this.title = item.data().title;
        this.image = item.data().image;
        this.link = item.data().link;
        this.selectedTags = new Set(item.data().tags);
      } else {
        this.title = this.searchTitle;
        this.image = this.searchImage;
        this.link = this.searchLink;
      }
    },

    tagClasses(tag) {
      return [
        "tag",
        "is-clickable",
        { "is-primary": this.selectedTags.has(tag.id) },
      ];
    },

    onClickTag(tag) {
      if (this.selectedTags.has(tag.id)) {
        this.selectedTags.delete(tag.id);
      } else {
        this.selectedTags.add(tag.id);
      }
    },

    onAddTag() {
      const slug = slugify(this.newTag, { lower: true });
      if (slug && this.tags.findIndex((tag) => tag.id == slug) == -1) {
        this.tags.push({
          id: slug,
          name: this.newTag,
          isNew: true,
        });
        this.selectedTags.add(slug);
      }
      this.newTag = "";
    },

    async onSubmit() {
      for (const tag of this.tags) {
        if (tag.isNew) {
          await saveTag(this.entity, tag.id, { name: tag.name });
        }
      }
      const newItem = {
        title: this.title,
        image: this.image,
        tags: [...this.selectedTags],
      };
      if (this.entity != "games") {
        newItem.link = this.link;
      }
      await saveItem(
        this.entity,
        slugify(this.title, { lower: true }),
        newItem
      );
      this.$router.push({ name: "Items", params: { entity: this.entity } });
    },

    onCancel() {
      this.$router.push({ name: "Items", params: { entity: this.entity } });
    },

    async onDelete() {
      await deleteItem(this.entity, this.id);
      this.$router.push({ name: "Items", params: { entity: this.entity } });
    },

    searchImage() {
      var query = this.title;
      if (this.entity == 'games') {
        query += " game cover"
      } else if (this.entity == 'books') {
        query += " book cover"
      }  else if (this.entity == 'movies') {
        query += " movie poster"
      }  else if (this.entity == 'tv') {
        query += " tv show"
      }  else if (this.entity == 'comics') {
        query += " comic cover"
      }  else if (this.entity == 'manga') {
        query += " manga cover"
      } 
      window.open('https://duckduckgo.com/?t=h_&iax=images&ia=images&q=' + query, '_blank').focus();
    }
  },
};
</script>

<style scoped>
.image {
  width: 100%;
  border-radius: 5%;
  box-shadow: 2px 2px 5px rgb(44, 44, 44);
}

.tags {
  margin-top: 20px;
}

.buttons {
  margin-top: 30px;
}

.btn-link {
  margin-top: 10px;
}
</style>
