import { db } from '../firebase/config'
import { collection, getDocs, orderBy, query, setDoc, deleteDoc, doc } from '@firebase/firestore'

export const getTags = async (entity) => {
  const q = query(collection(db, getTable(entity)), orderBy('name', 'asc'))
  const res = await getDocs(q)
  const tags = res.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return tags
}

export const saveTag = async (entity, id, tag) => {
  setDoc(doc(db, getTable(entity), id), tag)
}

export const deleteTag = async (entity, id) => {
  deleteDoc(doc(db, getTable(entity), id))
}

function getTable(entity) {
  if (entity == 'comics') return 'comics-tags'
  if (entity == 'books') return 'books-tags'
  if (entity == 'games') return 'games-tags'
  if (entity == 'tv') return 'shows-tags'
  if (entity == 'manga') return 'manga-tags'
  if (entity == 'projects') return 'projects-tags'
}

