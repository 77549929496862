import { db } from '../firebase/config'
import { doc, setDoc, getDoc, collection, getDocs, orderBy, query, deleteDoc } from '@firebase/firestore'

export const getItems = async (entity) => {
  const q = query(collection(db, getTable(entity)), orderBy('title', 'asc'))
  const res = await getDocs(q)
  const comics = res.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return comics
}

export const getItem = async (entity, id) => {
  const docRef = doc(db, getTable(entity), id)
  const comic = await getDoc(docRef)
  return comic
}

export const saveItem = async (entity, id, item) => {
  setDoc(doc(db, getTable(entity), id), item)
}

export const deleteItem = async (entity, id) => {
  deleteDoc(doc(db, getTable(entity), id))
}

export const getSearchURL = (entity) => {
  if (entity == 'comics') {
    return 'https://puppet.pblrz.com/comic/search?q='
  } else if (entity == 'books') {
    return 'https://puppet.pblrz.com/book/search?q='
  } else if (entity == 'games') {
    return 'https://puppet.pblrz.com/game/search?q='
  } else if (entity == 'tv') {
    return 'https://puppet.pblrz.com/show/search?q='
  } else if (entity == 'manga') {
    return 'https://puppet.pblrz.com/manga/search?q='
  }
}

function getTable(entity) {
  if (entity == 'comics') return 'comics'
  if (entity == 'books') return 'books'
  if (entity == 'games') return 'games'
  if (entity == 'tv') return 'shows'
  if (entity == 'manga') return 'manga'
  if (entity == 'projects') return 'projects'
}
