<template>
  <div class="links" v-if="entity == 'comics'">
    <a
      :href="link"
      target="_blank"
      class="button is-info is-outlined is-fullwidth btn-link"
      >CBR</a
    >
    <a
      :href="'https://leagueofcomicgeeks.com/search?keyword=' + title"
      target="_blank"
      class="button is-info is-outlined is-fullwidth btn-link"
      >League</a
    >
  </div>
  <div class="links" v-if="entity == 'books'">
    <a
      :href="link"
      target="_blank"
      class="button is-info is-outlined is-fullwidth btn-link"
      >Goodreads</a
    >
  </div>
  <div class="links" v-if="entity == 'tv'">
    <a
      :href="link"
      target="_blank"
      class="button is-info is-outlined is-fullwidth btn-link"
      >JustWatch</a
    >
  </div>
  <div class="links" v-if="entity == 'games'">
    <a
      :href="'https://www.igdb.com/games/' + id"
      target="_blank"
      class="button is-info is-outlined is-fullwidth btn-link"
      >IGDB</a
    >
    <a
      :href="'https://www.backloggd.com/games/' + id"
      target="_blank"
      class="button is-info is-outlined is-fullwidth btn-link"
      >Backloggd</a
    >
  </div>
  <div class="links" v-if="entity == 'manga'">
    <a
      :href="link"
      target="_blank"
      class="button is-info is-outlined is-fullwidth btn-link"
      >Manga4life</a
    >
  </div>
  <div class="links" v-if="entity == 'projects'">
    <a
      :href="link"
      target="_blank"
      class="button is-info is-outlined is-fullwidth btn-link"
      >Link</a
    >
  </div>
</template>

<script>
export default {
  props: ["entity", "link", "title", "id"],
};
</script>

<style>
.links a {
  margin-top: 15px;
}
</style>
