<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item is-size-4 is-uppercase has-text-weight-bold" to="/">
        LOGBOOK
      </router-link>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        @click="showMenu = !showMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div :class="['navbar-menu', { 'is-active': showMenu }]">
      <div class="navbar-start">
        <router-link :to="{ name: 'Items', params: { entity: 'books' } }" class="navbar-item">
          Books
        </router-link>
        <router-link :to="{ name: 'Items', params: { entity: 'comics' } }" class="navbar-item">
          Comics
        </router-link>
        <router-link :to="{ name: 'Items', params: { entity: 'manga' } }" class="navbar-item">
          Manga
        </router-link>
        <router-link :to="{ name: 'Items', params: { entity: 'games' } }" class="navbar-item">
          Games
        </router-link>
        <router-link :to="{ name: 'Items', params: { entity: 'tv' } }" class="navbar-item">
          TV
        </router-link>
        <router-link :to="{ name: 'Items', params: { entity: 'projects' } }" class="navbar-item">
          Projects
        </router-link>
      </div>
      <div class="navbar-end">
        <a class="navbar-item" v-if="user" @click="logout"> Log out </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
    }
  },

  computed: {
    user() {
      return this.$store.state.user
    },
  },

  methods: {
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>

<style></style>
